import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["text", "cursor"]
  static values = {
    words: Array,
    typingSpeed: Number,
    deletingSpeed: Number,
    pauseDuration: Number
  }

  connect() {
    this.words = this.wordsValue
    this.typingSpeed = this.typingSpeedValue || 100
    this.deletingSpeed = this.deletingSpeedValue || 50
    this.pauseDuration = this.pauseDurationValue || 2000
    this.wordIndex = 0
    this.charIndex = 0
    this.isDeleting = false
    this.startTypewriter()
    this.blinkCursor()
  }

  startTypewriter() {
    const currentWord = this.words[this.wordIndex]
    let delay = this.isDeleting ? this.deletingSpeed : this.typingSpeed

    if (!this.isDeleting && this.charIndex < currentWord.length) {
      this.textTarget.textContent += currentWord.charAt(this.charIndex)
      this.charIndex++
    } else if (this.isDeleting && this.charIndex > 0) {
      this.textTarget.textContent = currentWord.substring(0, this.charIndex - 1)
      this.charIndex--
    } else if (this.charIndex === 0) {
      this.isDeleting = false
      this.wordIndex = (this.wordIndex + 1) % this.words.length
    } else {
      this.isDeleting = true
      delay = this.pauseDuration
    }

    setTimeout(() => this.startTypewriter(), delay)
  }

  blinkCursor() {
    setInterval(() => {
      this.cursorTarget.classList.toggle("opacity-0")
    }, 500)
  }
}
