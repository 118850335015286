import { Controller } from "@hotwired/stimulus"
import tippy, { roundArrow, hideAll } from 'tippy.js'

export default class extends Controller {
  static targets = ['template']
  static values = {
    content: {
      type: String,
    },
    placement: {
      type: String,
      default: 'bottom-start'
    },
    arrow: {
      type: Boolean,
      default: false
    },
    width: {
      type: Number,
      default: undefined
    },
    trigger: {
      type: String,
      default: undefined
    }
  }

  connect() {
    if (!this.hasContentValue && !this.hasTemplateTarget) {
      throw new Error('TippyController needs a content value or a template target')
    }

    this.instance = tippy(this.element, {
      content: this.content,
      allowHTML: true,
      placement: this.placementValue,
      arrow: this.arrowValue ? roundArrow : false,
      theme: 'light',
      maxWidth: this.widthValue,
      trigger: this.triggerValue,
      interactive: true,
      appendTo: document.body
    })
  }

  hide() {
    try {
      this.instance.hide()
    } catch (error) {
      hideAll()
    }
  }

  get content() {
    if (this.hasContentValue && this.contentValue) {
      return this.contentValue
    } else {
      return this.templateTarget.innerHTML
    }
  }
}
